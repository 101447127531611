import axios from '@/api/axios'

const Helpers = {
    nameCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        value = value.trim()
        return /^[а-яёa-z\.\(\)_0-9]+([- \`\'\(\)_0-9]{1}[а-яёa-z\.\(\)_0-9]+)*\.?$/i.test(value) ? true : false
    },
    statusCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^[1|2|3]$/.test(value) ? true : false
    },
    phoneCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^\+7\s\(9\d{2}\)\s\d{3}-\d{2}-\d{2}$/i.test(value) ? true : false;
    },
    emailCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^[\-а-яёa-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[\-a-zа-яё0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-zа-яё0-9]([\-а-яёa-z0-9]{0,61}[а-яёa-z0-9])?\.)+(?:[a-zа-яё0-9]{2,})$/i.test(value) ? true : false;
    },
    idCheck: value => {
        if (value === '' || value === undefined || value === null)
            return false;
        return /^[a-zA-Z0-9]{24}/.test(value) ? true : false ? true : false;
    },
}

export const globalMethods = {
    methods: {
        wsParseAnswer(message) {
            try {
                return JSON.parse(message)
            } catch (err) {
                console.error('SOCKET_ERROR => ', err)
                return false
            }

        },
        clearPhone(rawPhone) {
            let formattedPhone = rawPhone;
            if (formattedPhone) {
                formattedPhone = formattedPhone.replace(/\D/g, '');
                if (formattedPhone.charAt(0) === '7') {
                    formattedPhone = formattedPhone.substr(1);
                }
            }
            return formattedPhone;
        },
        handleClickOutside(e, ref) {
            const block = this.$refs[ref]
            if (block && block.contains(e.target)) {
                return false;
            }
            return true
        },
        checkName(data) {
            return new Promise((resolve, reject) => {
                if (!data?.name) {
                    return reject('Отсутсвует имя')
                } else if (!Helpers.nameCheck(data.name)) {
                    return reject('Некорректное имя')
                } else {
                    return resolve(data)
                }
            })
        },
        checkEmail(data) {
            return new Promise((resolve, reject) => {
                if (!data?.email) {
                    return reject('Отсутсвует email')
                } else if (!Helpers.emailCheck(data.email)) {
                    return reject('Некорректное email')
                } else {
                    return resolve(data)
                }
            })
        },
        checkId(data) {
            return new Promise((resolve, reject) => {
                if (!data?.id) {
                    return reject('Отсутсвует id')
                } else if (!Helpers.idCheck(data.id)) {
                    return reject('Некорректное id')
                } else {
                    return resolve(data)
                }
            })
        },
        checkStatus(data) {
            return new Promise((resolve, reject) => {
                if (!data?.status) {
                    return reject('Отсутсвует статус')
                } else if (!Helpers.statusCheck(data.status)) {
                    return reject('Некорректный статус')
                } else {
                    return resolve(data)
                }
            })
        },
        checkPhone(data) {
            return new Promise((resolve, reject) => {
                if (!data?.phone) {
                    return reject('Отсутсвует телефон')
                } else if (!Helpers.phoneCheck(data.phone)) {
                    return reject('Некорректный телефон')
                } else {
                    return resolve(data)
                }
            })
        },
        formattedPhoneNumber(number) {
            if (!number) {
                return ''
            }
            return number.replace('+', '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
        },
        valueModel(value, obj, field) {
            this.$set(obj, field, value)
        },
        checkAccess(adminType, forOpen) {
            if (Array.isArray(forOpen)) {
                if (forOpen.includes(adminType)) {
                    return true
                } else {
                    return false
                }
            } else {
                if (adminType === forOpen) {
                    return true
                } else {
                    return false
                }
            }
        },
        showModal(modalName) {
            this.$refs[modalName].show({}).then((result) => {
            })
        },
        hiddeModal(modalName) {
            this.$refs[modalName].show({}).then((result) => {
            })
        },
        sendRequest(method, data, url, currentHeaders = {}, preloader, plug) {
            const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
            if (!currentHeaders || !currentHeaders?.['Content-Type'] || !currentHeaders?.['content-type']) {
                axios.defaults.headers.common['Content-Type'] = 'application/json';
            }

            let headers = {
                withCredentials: true,
                ...currentHeaders,
            }

            const token = this.$cookies.get('token')
            if (token) {
                headers['Authorization'] = 'Bearer ' + token
            }

            if (preloader) {
                this.$store.commit('showPreloader', true)
            }
            const errorMessage = (err) => {
                // this.$router.push({ name: 'Monitoring' });
                if (err?.response?.status == 401) {
                    this.$cookies.remove('token')
                    window.location.href = '/'
                    return
                }
                
                if (err?.response?.data) {
                    if (err?.response?.headers['content-type'] && err.response.headers['content-type'].indexOf('text/html') > -1) {
                        return err.message
                    } else {
                        if (err?.response?.data?.message) {
                            return err.response.data.message;
                        } else {
                            return err.response.data;
                        }
                    }
                } else {
                    return err.message;
                }
            }
            return new Promise((resolve, reject) => {
                if (plug) {
                    setTimeout(() => {
                        this.$store.commit('showPreloader', false)
                        resolve([])
                    }, 1000)
                } else {
                    axios.request({
                        url,
                        method,
                        [dataOrParams]: data,
                        headers: headers
                    })
                        .then(({ data }) => {
                            resolve(data)
                        })
                        .catch((err) => {
                            reject(errorMessage(err))
                        })
                        .finally(() => {
                            this.$store.commit('showPreloader', false)
                        });
                }
            })
        },
        declOfNum(number, titles) {
            const cases = [2, 0, 1, 1, 1, 2];
            return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        },
        number_format(number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k)
                        .toFixed(prec);
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
                .split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '')
                .length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1)
                    .join('0');
            }
            return s.join(dec);
        },
        loadGuide(key, methodName) {
            return new Promise((resolve, reject) => {
                if (this.$store.state[key]) {
                    resolve(this.$store.state[key])
                } else {
                    this.sendRequest('GET', {}, methodName, {}, true)
                        .then(response => {
                            if (response) {
                                const language = this.$store.state.language
                                let data = resolve.reduce((result, item) => {
                                    const name = JSON.parse(item.name)[language]
                                    return { ...result, [item.id]: name }
                                })
                                this.$store.commit(`set${key}`, data)
                            }
                        })
                        .catch(err => {
                            this.$store.commit('setError', err)
                        })
                }
            })
        }
    }
}
