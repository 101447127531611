<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Error from "@/components/Errors";
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import { debounce } from 'lodash'

export default {
  name: 'App',
  components: {
    Error,
  },
  mixins: [globalMethods],
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout"
    },
    ...mapState({
      socket: state => state.socket,
    })
  },
  mounted() {    
    this.updateWindowWidth = debounce(this.updateWindowWidth, 200)
    window.addEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.$store.commit('setWindowWidth', window.innerWidth)
    },    
  }
}
</script>

<style>
#app {}
</style>
