import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login"
import Main from "../views/Main"
import History from "../views/History"
import axios from "@/api/axios"
import store from "@/store"

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { layout: "login-layout" }
    },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
    {
        path: '/history',
        name: 'History',
        component: History,
        meta: { requiresAuth: true, layout: "default-layout" }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (Vue.$cookies.get('token')) {
            next()
        } else {
            next({ path: '/' })
        }
    } else {
        next()
    }

})

export default router
