<template>
  <div class="default-template tm-page">
    <div class="header">
      <div class="logo">
        <img src="../assets/Logo-white.svg">
      </div>
      <div class="menu">
        <router-link :to="{ path: m.url }" v-for="(m, ind) of menu" :key="'menu-' + ind" exact
          exact-active-class="active">
          <span>{{ m.name }}</span>
        </router-link>
      </div>
      <div class="user">
        <span class="name">{{ parnterName }}</span>
        <button class="ghost logout" @click="logout()">
          <Icon width="24" height="24" viewBox="0 0 28 28" strockeWidth="2" :fill="'currentColor'" :stroke="null"
            :iconName="'logout'" />
        </button>
      </div>
    </div>
    <div class="content-block">
      <slot />
    </div>
    <!-- <Header /> -->
    <!-- <Sidebar /> -->
    <Preloader />
    <Error />
  </div>
</template>

<script>
// import Header from "../components/Header";
// import Sidebar from "../components/Sidebar";
import Preloader from "../components/Preloader";
import Error from "../components/Errors";
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import { RouterLink } from "vue-router";

export default {
  name: "default",
  components: { Preloader, Error, RouterLink, },
  mixins: [globalMethods],

  computed: {
    ...mapState({
      user: state => state.user,
      windowWidth: state => state.windowWidth,
    }),
    isMobyle() {
      return this.windowWidth <= 560
    },
  },
  mounted() {
    this.parnterName = this.$cookies.get('parnter_name')
    // this.parnerID = this.$cookies.get('partnerID')
    this.$store.dispatch('fetchPartnerIDFromCookie');
  },
  watch: {
    showUserMenu: function (val) {
      const clickOutside = (e) => {
        if (this.handleClickOutside(e, 'userMenu')) {
          this.$set(this, 'showUserMenu', false)
        }
      }
      if (val) {
        document.addEventListener('click', clickOutside);
      } else {
        document.removeEventListener('click', clickOutside);
      }
      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  },
  data() {
    return {
      parnerID: null,
      parnterName: null,      
      menu: [
        {
          url: '/main',
          name: 'Главная',
        },
        {
          url: '/history',
          name: 'История операций',
        },
      ]
    }
  },
  methods: {
    logout() {
      this.$cookies.remove("token");
      location.href = "/"
    }
  }

}
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: $color-orange;
}

* {
  outline: none;
}

body,
html {
  font-family: "Inter";
  font-weight: 400;
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 15px;
  color: $color-black;
}

.default-template {
  .header {
    background: #003A63;
    padding: 22px 49px;
    color: #fff;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 80px;
    align-items: center;

    .logo {
      height: 40px;
    }

    .menu {
      display: flex;
      align-items: center;

      a {
        display: block;
        position: relative;
        color: #809CB1 !important;
        transition: all .3s;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          display: block;
          margin-top: 10px;
          background: rgba(0, 0, 0, 0);
          transition: all .3s;
        }

        &.active {
          color: #fff !important;

          &::after {
            background: #fff;
          }
        }

        &:hover {
          color: #fff !important;
        }

        span {
          display: block;
          padding: 0 16px;
        }
      }
    }


  }

  .content-block {
    padding: 22px 49px;
    background: #fff;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .user {
    display: flex;
    align-items: center;

    .name {
      white-space: nowrap;
      margin-right: 10px;
    }

    .logout {
      color: #fff !important;

      * {
        transition: all .1s;
      }

      &:hover {
        color: #809CB1 !important;
      }

    }
  }
}
</style>
