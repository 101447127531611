<template>
    <div class="login-form">
        <v-form @submit.prevent="false" class="form-login">
            <img src="../../assets/Logo.svg">

            <template v-if="area == 'login'">
                <h1>Авторизация</h1>
                <v-text-field v-model="login" name="login" autocomplete="off" outlined class="input" placeholder="Email"
                    :error-messages="errors.login" :error="!!errors.login"></v-text-field>

                <v-text-field v-model="password" name="password"
                    :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'" outlined placeholder="Пароль" color="secondary"
                    class="input password-input" :error-messages="errors.password" :error="!!errors.password">
                </v-text-field>

                <a @click.prevent="area = 'forgot'" class="gray mt-38">Забыли пароль?</a>
                <v-btn type="button" class="button mt-26" variant="tonal" color="primary" block :disabled="buttonDisabled"
                    @click="onSubmit">
                    Войти
                </v-btn>
                <v-btn type="button" class="button mt-26 secondary" variant="outline" color="primary" block
                    @click="area = 'registration'">
                    Зарегистрироваться
                </v-btn>
            </template>
            <template v-else-if="area == 'forgot'">
                <h1>Восстановление пароля</h1>
                <v-text-field v-model="email" name="email" autocomplete="off" outlined class="input" placeholder="Email"
                    :error-messages="errors.login" :error="!!errors.login"></v-text-field>
                <v-btn type="button" class="button mt-26" variant="tonal" color="primary" block :disabled="buttonDisabled"
                    @click="onSubmit">
                    Восстановить
                </v-btn>

            </template>
            <template v-else-if="area == 'registration'">
                <h1>Регистрация</h1>
                <v-text-field name="name" v-model="registration.name" autocomplete="off" outlined class="input"
                    placeholder="Ваше имя" :error-messages="errors.name" :error="!!errors.name"></v-text-field>
                <v-text-field name="organization" v-model="registration.organization" autocomplete="off" outlined
                    class="input" placeholder="Название организации" :error-messages="errors.organization"
                    :error="!!errors.organization"></v-text-field>
                <v-text-field name="email" v-model="registration.email" autocomplete="off" outlined class="input"
                    placeholder="Email" :error-messages="errors.email" :error="!!errors.email"></v-text-field>
                <!-- <v-text-field name="password" v-model="registration.password"
                    :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'" outlined placeholder="Пароль" color="secondary"
                    class="input password-input" :error-messages="errors.password" :error="!!errors.password">
                </v-text-field>
                <v-text-field name="password_repeate" v-model="registration.password_repeate"
                    :append-icon="value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'" outlined placeholder="Пароль" color="secondary"
                    class="input password-input" :error-messages="errors.password" :error="!!errors.password">
                </v-text-field> -->
                <v-checkbox v-model="agree" label="Даю согласие на обработку персональных данных"></v-checkbox>

                <v-btn @click="onSubmit" class="button mt-6" variant="tonal" color="primary" block
                    :disabled="buttonDisabled">
                    Зарегистрироваться
                </v-btn>
                <v-btn type="button" class="button mt-26 secondary" variant="outline" color="primary" block
                    @click="area = 'login'">
                    У меня есть аккуант
                </v-btn>
            </template>
            <router-link :to="{ path: '#' }" class="mt-48">
                Политика конфиденциальности
            </router-link>
        </v-form>
        <Modal ref="notification" :notFooterBorder="true" padding="40" :hiddeCloseBtn="true" :hiddeHeader="true"
            :hiddeFooter="true">
            <template v-slot:body>
                <div class="notification-success">
                    <div class="icon">
                        <Icon width="20" height="20" viewBox="0 0 15 15" strockeWidth="3.5" :iconName="'check'"
                            stroke="#fff" />
                    </div>
                    <div class="caption">
                        {{ notif.caption }}
                    </div>
                    {{ notif.message }}
                </div>
                <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" block @click="close()">
                    Закрыть
                </v-btn>
            </template>
        </Modal>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
import { RouterLink } from "vue-router";

export default {
    name: 'LoginPage',
    components: { RouterLink },
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            area: 'login',
            login: '',
            email: '',
            buttonDisabled: false,
            errors: {
                login: null,
                password: null,
                password_repeate: null,
                phone: null,
                email: null,
                organization: null,
            },
            password: '',
            registration: {
                organization: '',
                email: '',
                phone: '',
                password: '',
                password_repeate: '',
                name: '',
            },
            agree: false,
            remember: false,
            dialog: false,
            notif: {
                caption: '',
                message: '',
            }
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
        if (this.$cookies.get('token')) {
            this.$router.push({ name: 'Main' });
        }
    },
    watch: {},
    methods: {
        close() {
            if (
                this.area == 'registration'
                || this.area == 'forgot'
            ) {
                this.area = 'login'
            }
            this.$refs['notification']._confirm()
        },
        onSubmit() {
            this.$set(this, 'buttonDisabled', true)
            let data = {},
                url = ''
            if (this.area == 'login') {
                data = {
                    login: this.login,
                    password: this.password
                }
                url = '/partnerLogin'
            } else if (this.area == 'forgot') {
                data = {
                    email: this.email,
                }
                url = '/restorePartnerPassword'

            } else if (this.area == 'registration') {
                data = {
                    ...this.registration
                }
                url = '/addPartner'
            }
            let request = {}
            Object.entries(data).filter(([key, value]) => value != '').forEach(([key, value]) => { request[key] = value })

            this.sendRequest('POST', request, url, {
                // 'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'text/plain',
            }, true, false)
                .then(response => {
                    this.$set(this, 'buttonDisabled', false)
                    if (this.area == 'login') {
                        this.$cookies.set("parnter_name", response.parnter_name, {
                            path: "/",
                            maxAge: this.$moment().unix() + 60 * 60 * 12,
                        });
                        this.$cookies.set("organization", response.organization, {
                            path: "/",
                            maxAge: this.$moment().unix() + 60 * 60 * 12,
                        });
                        this.$cookies.set("token", response.token, {
                            path: "/",
                            maxAge: this.$moment().unix() + 60 * 60 * 12,
                        });
                        this.$cookies.set("partnerID", response.partner_id, {
                            path: "/",
                            maxAge: this.$moment().unix() + 60 * 60 * 12,
                        });
                        this.$router.push({ name: 'Main' });
                    } else if (this.area == 'forgot') {
                        this.email = null
                        this.notif.caption = 'Новый пароль выслан вам на почту'
                        this.notif.massege = 'Новый пароль выслан вам на почту'
                        this.showModal('notification')
                    } else if (this.area == 'registration') {
                        this.email = null
                        this.notif.caption = 'Вы зарегистрировались! Пароль отправлен на почту указанную при регистрации'
                        this.showModal('notification')
                    }
                })
                .catch(err => {
                    this.$set(this, 'errors', {
                        login: 'Неверный логин',
                        password: 'Неверный пароль',
                    })
                    this.$set(this, 'buttonDisabled', false)
                    // this.$store.commit('setError', err)
                })

        }
    }
}
</script>
<style lang="scss">
.login-form {
    width: 323px;

    .input {
        // height: 48px;
        /* задаем высоту в пикселях */
    }

    img {
        width: 190px;
        margin: auto;
        display: block;
        margin-bottom: 83px;
    }

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
        text-align: center;
        margin-bottom: 58px;
    }

    .caption {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1A1A1A;
        display: block;
        margin-bottom: 20px;
        text-align: center;
    }

    .remember-me {
        margin-top: 30px;
        text-align: center;

        span {
            color: #629F33;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .forgot {
        .heading {
            font-family: 'Circe';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;

            color: #000000;
        }

        .message {
            padding-top: 15px;
            font-family: 'Circe';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #1A1A1A;
        }
    }

    .mt-26 {
        margin-top: 26px;
    }

    .mt-38 {
        margin-top: 38px;
    }

    .mt-48 {
        margin-top: 48px;
    }

    a {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        color: #003A63 !important;
        display: block;
        text-align: center;
        cursor: pointer;

        &.gray {
            color: #999999 !important;
        }
    }

    button {
        &.secondary {
            background: #fff !important;
            border: 1px solid #003A63 !important;
            color: #003A63 !important;
        }
    }

    .caption {
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #333333;
        margin-bottom: 16px;
    }
}
</style>
