<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox"
         :aria-labelledby="iconName"
         role="presentation" :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round"
         stroke-linejoin="round"
         :fill="fill" enable-background="new 0 0 551.13 551.13" @click="click()">


        <path v-if="iconName == 'check'" d="M13.3337 4L6.00033 11.3333L2.66699 8" :stroke="stroke"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path v-else-if="iconName == 'x'" d="M12 4L4 12M4 4L12 12" :stroke="stroke" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path v-else-if="iconName == 'chevron-down'" d="M4 6L8 10L12 6" :stroke="stroke" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path v-else-if="iconName == 'plus'" d="M7.99967 3.33325V12.6666M3.33301 7.99992H12.6663" :stroke="stroke"
              stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'check-square'"
              d="M6 7.33333L8 9.33333L14.6667 2.66667M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667"
              :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'file-text'"
              d="M9.33366 1.33325H4.00033C3.6467 1.33325 3.30756 1.47373 3.05752 1.72378C2.80747 1.97382 2.66699 2.31296 2.66699 2.66659V13.3333C2.66699 13.6869 2.80747 14.026 3.05752 14.2761C3.30756 14.5261 3.6467 14.6666 4.00033 14.6666H12.0003C12.3539 14.6666 12.6931 14.5261 12.9431 14.2761C13.1932 14.026 13.3337 13.6869 13.3337 13.3333V5.33325M9.33366 1.33325L13.3337 5.33325M9.33366 1.33325L9.33366 5.33325H13.3337M10.667 8.66659H5.33366M10.667 11.3333H5.33366M6.66699 5.99992H5.33366"
              :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'x-circle'"
              d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
              :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'phone'"
              d="M15 2V8M15 8H21M15 8L22 1M21.0004 16.92V19.92C21.0016 20.1985 20.9445 20.4741 20.8329 20.7293C20.7214 20.9845 20.5577 21.2136 20.3525 21.4018C20.1473 21.5901 19.905 21.7335 19.6412 21.8227C19.3773 21.9119 19.0978 21.945 18.8204 21.92C15.7433 21.5856 12.7874 20.5341 10.1904 18.85C7.77425 17.3146 5.72576 15.2661 4.19042 12.85C2.5004 10.2412 1.44866 7.27097 1.12042 4.17997C1.09543 3.90344 1.1283 3.62474 1.21692 3.3616C1.30555 3.09846 1.44799 2.85666 1.63519 2.6516C1.82238 2.44653 2.05023 2.28268 2.30421 2.1705C2.5582 2.05831 2.83276 2.00024 3.11042 1.99997H6.11042C6.59573 1.9952 7.06621 2.16705 7.43418 2.48351C7.80215 2.79996 8.0425 3.23942 8.11042 3.71997C8.23704 4.68004 8.47187 5.6227 8.81042 6.52997C8.94497 6.8879 8.97408 7.27689 8.89433 7.65086C8.81457 8.02482 8.62928 8.36809 8.36042 8.63998L7.09042 9.90998C8.51398 12.4135 10.5869 14.4864 13.0904 15.91L14.3604 14.64C14.6323 14.3711 14.9756 14.1858 15.3495 14.1061C15.7235 14.0263 16.1125 14.0554 16.4704 14.19C17.3777 14.5285 18.3204 14.7634 19.2804 14.89C19.7662 14.9585 20.2098 15.2032 20.527 15.5775C20.8441 15.9518 21.0126 16.4296 21.0004 16.92Z"
              :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'email'"
              d="M21 3C21 1.9 20.1 1 19 1H3C1.9 1 1 1.9 1 3M21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3M21 3L11 10L1 3"
              :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'save'"
              d="M10.3333 13V7.66667H3.66667V13M3.66667 1V4.33333H9M11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H9.66667L13 4.33333V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13Z"
              :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'printer'"
              d="M3.99967 5.99992V1.33325H11.9997V5.99992M3.99967 11.9999H2.66634C2.31272 11.9999 1.97358 11.8594 1.72353 11.6094C1.47348 11.3593 1.33301 11.0202 1.33301 10.6666V7.33325C1.33301 6.97963 1.47348 6.64049 1.72353 6.39044C1.97358 6.14039 2.31272 5.99992 2.66634 5.99992H13.333C13.6866 5.99992 14.0258 6.14039 14.2758 6.39044C14.5259 6.64049 14.6663 6.97963 14.6663 7.33325V10.6666C14.6663 11.0202 14.5259 11.3593 14.2758 11.6094C14.0258 11.8594 13.6866 11.9999 13.333 11.9999H11.9997M3.99967 9.33325H11.9997V14.6666H3.99967V9.33325Z"
              :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'sort'"
              d="M13.3287 3.82843L10.5003 1M10.5003 1L7.67188 3.82843M10.5003 1V11.0001M6.82873 8.17172L4.0003 11.0002M4.0003 11.0002L1.17188 8.17172M4.0003 11.0002L4.0003 1.00007"
              :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        <path v-else-if="iconName == 'filter'"
              d="M14.6666 2H1.33325L6.66659 8.30667V12.6667L9.33325 14V8.30667L14.6666 2Z"
              :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        <template v-else-if="iconName == 'trash'">
            <path
                    d="M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M3.33331 5.83333H16.6666M15.8333 5.83333L15.1108 15.9517C15.0809 16.3722 14.8927 16.7657 14.5842 17.053C14.2758 17.3403 13.8699 17.5 13.4483 17.5H6.55165C6.1301 17.5 5.7242 17.3403 5.41572 17.053C5.10723 16.7657 4.91908 16.3722 4.88915 15.9517L4.16665 5.83333H15.8333ZM12.5 5.83333V3.33333C12.5 3.11232 12.4122 2.90036 12.2559 2.74408C12.0996 2.5878 11.8877 2.5 11.6666 2.5H8.33331C8.1123 2.5 7.90034 2.5878 7.74406 2.74408C7.58778 2.90036 7.49998 3.11232 7.49998 3.33333V5.83333H12.5Z"
                    :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'eye'">
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
        </template>
        <template v-else-if="iconName == 'money'">
            <path
                    d="M10 13.8333C11.8409 13.8333 13.3333 12.3409 13.3333 10.5C13.3333 8.65905 11.8409 7.16667 10 7.16667C8.15905 7.16667 6.66667 8.65905 6.66667 10.5C6.66667 12.3409 8.15905 13.8333 10 13.8333Z"
                    :fill="stroke"/>
            <path
                    d="M15.8333 10.5C15.8333 10.9602 15.4602 11.3333 15 11.3333C14.5398 11.3333 14.1667 10.9602 14.1667 10.5C14.1667 10.0398 14.5398 9.66667 15 9.66667C15.4602 9.66667 15.8333 10.0398 15.8333 10.5Z"
                    :fill="stroke"/>
            <path
                    d="M5 11.3333C5.46024 11.3333 5.83333 10.9602 5.83333 10.5C5.83333 10.0398 5.46024 9.66667 5 9.66667C4.53976 9.66667 4.16667 10.0398 4.16667 10.5C4.16667 10.9602 4.53976 11.3333 5 11.3333Z"
                    :fill="stroke"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.28213 3.00936C2.2167 3.00317 2.15039 3 2.08333 3C0.93274 3 0 3.93274 0 5.08333C0 5.15039 0.00316779 5.2167 0.00936189 5.28213C0.0031636 5.35393 0 5.4266 0 5.5V15.5C0 15.5734 0.0031636 15.6461 0.00936189 15.7179C0.0031678 15.7833 0 15.8496 0 15.9167C0 17.0673 0.93274 18 2.08333 18C2.15039 18 2.2167 17.9968 2.28213 17.9906C2.35393 17.9968 2.4266 18 2.5 18H17.5C17.5734 18 17.6461 17.9968 17.7179 17.9906C17.7833 17.9968 17.8496 18 17.9167 18C19.0673 18 20 17.0673 20 15.9167C20 15.8496 19.9968 15.7833 19.9906 15.7179C19.9968 15.6461 20 15.5734 20 15.5V5.5C20 5.4266 19.9968 5.35393 19.9906 5.28213C19.9968 5.2167 20 5.15039 20 5.08333C20 3.93274 19.0673 3 17.9167 3C17.8496 3 17.7833 3.00317 17.7179 3.00936C17.6461 3.00316 17.5734 3 17.5 3H2.5C2.4266 3 2.35393 3.00316 2.28213 3.00936ZM4.12499 4.66667C4.15232 4.8013 4.16667 4.94064 4.16667 5.08333C4.16667 6.23393 3.23393 7.16667 2.08333 7.16667C1.94064 7.16667 1.8013 7.15232 1.66667 7.12499V13.875C1.8013 13.8477 1.94064 13.8333 2.08333 13.8333C3.23393 13.8333 4.16667 14.7661 4.16667 15.9167C4.16667 16.0594 4.15232 16.1987 4.12499 16.3333H15.875C15.8477 16.1987 15.8333 16.0594 15.8333 15.9167C15.8333 14.7661 16.7661 13.8333 17.9167 13.8333C18.0594 13.8333 18.1987 13.8477 18.3333 13.875V7.12499C18.1987 7.15232 18.0594 7.16667 17.9167 7.16667C16.7661 7.16667 15.8333 6.23393 15.8333 5.08333C15.8333 4.94064 15.8477 4.8013 15.875 4.66667H4.12499Z"
                  :fill="stroke"/>
        </template>
        <template v-else-if="iconName == 'calendar'">
            <path d="M6.66667 5.83333V2.5M13.3333 5.83333V2.5M5.83333 9.16667H14.1667M4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V5.83333C17.5 5.39131 17.3244 4.96738 17.0118 4.65482C16.6993 4.34226 16.2754 4.16667 15.8333 4.16667H4.16667C3.72464 4.16667 3.30072 4.34226 2.98816 4.65482C2.67559 4.96738 2.5 5.39131 2.5 5.83333V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5Z"
                  :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>

        </template>
        <template v-else-if="iconName == 'logout'">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.20005 4.20001C3.82875 4.20001 3.47265 4.34751 3.2101 4.61006C2.94755 4.87261 2.80005 5.22871 2.80005 5.60001V22.4C2.80005 22.7713 2.94755 23.1274 3.2101 23.39C3.47265 23.6525 3.82875 23.8 4.20005 23.8C4.57135 23.8 4.92745 23.6525 5.19 23.39C5.45255 23.1274 5.60005 22.7713 5.60005 22.4V5.60001C5.60005 5.22871 5.45255 4.87261 5.19 4.61006C4.92745 4.34751 4.57135 4.20001 4.20005 4.20001ZM18.6102 17.2102C18.3552 17.4743 18.2141 17.8279 18.2173 18.195C18.2205 18.562 18.3677 18.9132 18.6273 19.1728C18.8869 19.4323 19.238 19.5796 19.6051 19.5828C19.9722 19.5859 20.3258 19.4448 20.5898 19.1898L24.7899 14.9898C25.0523 14.7273 25.1997 14.3712 25.1997 14C25.1997 13.6288 25.0523 13.2728 24.7899 13.0102L20.5898 8.81021C20.4607 8.6765 20.3062 8.56984 20.1354 8.49647C19.9646 8.4231 19.7809 8.38448 19.595 8.38286C19.4091 8.38125 19.2248 8.41667 19.0527 8.48706C18.8807 8.55745 18.7243 8.66141 18.5929 8.79286C18.4614 8.92431 18.3575 9.08062 18.2871 9.25268C18.2167 9.42473 18.1813 9.60908 18.1829 9.79497C18.1845 9.98087 18.2231 10.1646 18.2965 10.3354C18.3699 10.5062 18.4765 10.6607 18.6102 10.7898L20.4204 12.6H9.80005C9.42875 12.6 9.07265 12.7475 8.8101 13.0101C8.54755 13.2726 8.40005 13.6287 8.40005 14C8.40005 14.3713 8.54755 14.7274 8.8101 14.99C9.07265 15.2525 9.42875 15.4 9.80005 15.4H20.4204L18.6102 17.2102Z"
                  :fill="fill"/>
        </template>
        <template v-else-if="iconName == 'extension'">
            <g clip-path="url(#clip0_2953_2496)">
                <path
                        d="M0.833313 3.33337V8.33337M0.833313 8.33337H5.83331M0.833313 8.33337L4.69998 4.70004C5.85074 3.55122 7.34369 2.80751 8.95388 2.58097C10.5641 2.35442 12.2043 2.65732 13.6273 3.44401C15.0504 4.2307 16.1793 5.45857 16.8438 6.94262C17.5084 8.42667 17.6726 10.0865 17.3118 11.672C16.951 13.2575 16.0847 14.6828 14.8434 15.7332C13.6021 16.7835 12.0531 17.402 10.4297 17.4954C8.80638 17.5889 7.19663 17.1522 5.84303 16.2512C4.48943 15.3502 3.46531 14.0337 2.92498 12.5"
                        :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 7.5V11.6667L12.5 12.9167" :stroke="stroke" :stroke-width="strockeWidth"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2953_2496">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'selector'">
            <path d="M6.66669 7.50002L10 4.16669L13.3334 7.50002M13.3334 12.5L10 15.8334L6.66669 12.5" :stroke="stroke"
                  :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'photo'">
            <g clip-path="url(#clip0_2491_26661)">
                <path
                        d="M22.9997 18.9998C22.9997 19.5302 22.789 20.0389 22.4139 20.414C22.0388 20.789 21.5301 20.9998 20.9997 20.9998H2.99997C2.46955 20.9998 1.96085 20.789 1.58578 20.414C1.21071 20.0389 1 19.5302 1 18.9998V7.99993C1 7.4695 1.21071 6.9608 1.58578 6.58574C1.96085 6.21067 2.46955 5.99996 2.99997 5.99996H6.99992L8.99989 3H14.9998L16.9998 5.99996H20.9997C21.5301 5.99996 22.0388 6.21067 22.4139 6.58574C22.789 6.9608 22.9997 7.4695 22.9997 7.99993V18.9998Z"
                        stroke="#1A1A1A" stroke-width="2.39997" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                        d="M11.9998 16.9998C14.209 16.9998 15.9998 15.209 15.9998 12.9999C15.9998 10.7908 14.209 8.99992 11.9998 8.99992C9.79074 8.99992 7.9999 10.7908 7.9999 12.9999C7.9999 15.209 9.79074 16.9998 11.9998 16.9998Z"
                        stroke="#1A1A1A" stroke-width="2.39997" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2491_26661">
                    <rect width="23.9997" height="23.9997" fill="white"/>
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'edit'">
            <path
                    d="M9.16665 4.16664H4.99998C4.55795 4.16664 4.13403 4.34223 3.82147 4.65479C3.50891 4.96736 3.33331 5.39128 3.33331 5.83331V15C3.33331 15.442 3.50891 15.8659 3.82147 16.1785C4.13403 16.491 4.55795 16.6666 4.99998 16.6666H14.1666C14.6087 16.6666 15.0326 16.491 15.3452 16.1785C15.6577 15.8659 15.8333 15.442 15.8333 15V10.8333M14.655 2.98831C14.8087 2.82912 14.9926 2.70215 15.196 2.6148C15.3993 2.52746 15.618 2.48148 15.8393 2.47956C16.0606 2.47763 16.2801 2.5198 16.4849 2.6036C16.6897 2.6874 16.8758 2.81116 17.0323 2.96765C17.1888 3.12414 17.3125 3.31022 17.3963 3.51505C17.4801 3.71988 17.5223 3.93934 17.5204 4.16064C17.5185 4.38194 17.4725 4.60064 17.3851 4.80398C17.2978 5.00732 17.1708 5.19123 17.0116 5.34497L9.85665 12.5H7.49998V10.1433L14.655 2.98831Z"
                    :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'minus-circle'">
            <path
                    d="M5.33301 7.99992H10.6663M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                    :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'plus-circle'">
            <g clip-path="url(#clip0_2491_26678)">
                <path
                        d="M7.99967 5.33325V10.6666M5.33301 7.99992H10.6663M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                        stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2491_26678">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'users'">
            <g clip-path="url(#clip0_3428_10497)">
                <path
                        d="M14.1667 17.5V15.8333C14.1667 14.9493 13.8155 14.1014 13.1904 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16668C3.28262 12.5 2.43478 12.8512 1.80965 13.4763C1.18453 14.1014 0.833344 14.9493 0.833344 15.8333V17.5M19.1667 17.5V15.8333C19.1661 15.0948 18.9203 14.3773 18.4678 13.7936C18.0153 13.2099 17.3818 12.793 16.6667 12.6083M13.3333 2.60833C14.0504 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0504 8.88308 13.3333 9.06667M10.8333 5.83333C10.8333 7.67428 9.34096 9.16667 7.50001 9.16667C5.65906 9.16667 4.16668 7.67428 4.16668 5.83333C4.16668 3.99238 5.65906 2.5 7.50001 2.5C9.34096 2.5 10.8333 3.99238 10.8333 5.83333Z"
                        :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3428_10497">
                    <rect width="20" height="20" :fill="stroke"/>
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'list'">
            <line x1="8" y1="6" x2="21" y2="6"></line>
            <line x1="8" y1="12" x2="21" y2="12"></line>
            <line x1="8" y1="18" x2="21" y2="18"></line>
            <line x1="3" y1="6" x2="3.01" y2="6"></line>
            <line x1="3" y1="12" x2="3.01" y2="12"></line>
            <line x1="3" y1="18" x2="3.01" y2="18"></line>
        </template>
        <template v-else-if="iconName == 'photograph'">
            <path d="M5.3335 21.3333L11.4482 15.2186C11.9482 14.7187 12.6264 14.4378 13.3335 14.4378C14.0406 14.4378 14.7188 14.7187 15.2188 15.2186L21.3335 21.3333M18.6668 18.6666L20.7815 16.5519C21.2816 16.052 21.9597 15.7712 22.6668 15.7712C23.3739 15.7712 24.0521 16.052 24.5522 16.5519L26.6668 18.6666M18.6668 10.6666H18.6802M8.00016 26.6666H24.0002C24.7074 26.6666 25.3857 26.3856 25.8858 25.8855C26.3859 25.3854 26.6668 24.7072 26.6668 23.9999V7.99992C26.6668 7.29267 26.3859 6.6144 25.8858 6.1143C25.3857 5.6142 24.7074 5.33325 24.0002 5.33325H8.00016C7.29292 5.33325 6.61464 5.6142 6.11454 6.1143C5.61445 6.6144 5.3335 7.29267 5.3335 7.99992V23.9999C5.3335 24.7072 5.61445 25.3854 6.11454 25.8855C6.61464 26.3856 7.29292 26.6666 8.00016 26.6666Z"
                  :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'box'">
            <path
                    d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
            </path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </template>
        <template v-else-if="iconName == 'user'">
            <path
                    d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'arrow-left'">
            <path d="M19 12H5M5 12L12 19M5 12L12 5" :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </template>
        <template v-else-if="iconName == 'chevron-right'">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.29303 14.7069C7.10556 14.5194 7.00024 14.2651 7.00024 13.9999C7.00024 13.7348 7.10556 13.4804 7.29303 13.2929L10.586 9.99992L7.29303 6.70692C7.11087 6.51832 7.01008 6.26571 7.01236 6.00352C7.01463 5.74132 7.1198 5.49051 7.30521 5.3051C7.49062 5.11969 7.74143 5.01452 8.00363 5.01224C8.26583 5.00997 8.51843 5.11076 8.70703 5.29292L12.707 9.29292C12.8945 9.48045 12.9998 9.73475 12.9998 9.99992C12.9998 10.2651 12.8945 10.5194 12.707 10.7069L8.70703 14.7069C8.5195 14.8944 8.26519 14.9997 8.00003 14.9997C7.73487 14.9997 7.48056 14.8944 7.29303 14.7069Z"
                  :fill="fill"/>
        </template>
        <template v-else-if="iconName == 'chevron-left'">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.707 14.7069C12.8945 14.5194 12.9998 14.2651 12.9998 13.9999C12.9998 13.7348 12.8945 13.4804 12.707 13.2929L9.414 9.99992L12.707 6.70692C12.8892 6.51832 12.99 6.26571 12.9877 6.00352C12.9854 5.74132 12.8802 5.49051 12.6948 5.3051C12.5094 5.11969 12.2586 5.01452 11.9964 5.01224C11.7342 5.00997 11.4816 5.11076 11.293 5.29292L7.293 9.29292C7.10553 9.48045 7.00021 9.73475 7.00021 9.99992C7.00021 10.2651 7.10553 10.5194 7.293 10.7069L11.293 14.7069C11.4805 14.8944 11.7348 14.9997 12 14.9997C12.2652 14.9997 12.5195 14.8944 12.707 14.7069Z"
                  fill="#6F727A"/>
        </template>
        <template v-else-if="iconName == 'photo'">
            <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
            <circle cx="12" cy="13" r="4"></circle>
        </template>
        <template v-else-if="iconName == 'rubl'">
            <g clip-path="url(#clip0_3483_9519)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.3333 7.91658C13.3333 9.80873 11.8358 11.25 10 11.5901V12.4999H11.6667C12.1269 12.4999 12.5 12.873 12.5 13.3333C12.5 13.7935 12.1269 14.1666 11.6667 14.1666H10V14.9999C10 15.4602 9.62691 15.8333 9.16668 15.8333C8.70644 15.8333 8.33334 15.4602 8.33334 14.9999V14.1666H7.50001C7.03977 14.1666 6.66668 13.7935 6.66668 13.3333C6.66668 12.873 7.03977 12.4999 7.50001 12.4999H8.33334V4.99992C8.33334 4.53968 8.70644 4.16658 9.16668 4.16658C11.3599 4.16658 13.3333 5.74447 13.3333 7.91658ZM11.6667 7.91658C11.6667 8.75577 11.0202 9.58487 10 9.88352V5.94965C11.0202 6.2483 11.6667 7.0774 11.6667 7.91658Z"
                      fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10 0.833252C4.9374 0.833252 0.833344 4.93731 0.833344 9.99992C0.833344 15.0625 4.9374 19.1666 10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252ZM2.50001 9.99992C2.50001 5.85778 5.85787 2.49992 10 2.49992C14.1421 2.49992 17.5 5.85778 17.5 9.99992C17.5 14.1421 14.1421 17.4999 10 17.4999C5.85787 17.4999 2.50001 14.1421 2.50001 9.99992Z"
                      fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3483_9519">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'clock'">
            <g clip-path="url(#clip0_2491_26434)">
                <path
                        d="M7.99967 3.99992V7.99992L10.6663 9.33325M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                        :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2491_26434">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </template>
    </svg>
</template>
<script>
export default {
    props: {
        iconName: {
            type: String,
            default: "box",
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        stroke: {
            type: String,
            default: "currentColor",
        },
        viewBox: {
            type: String,
            default: "0 0 24 24",
        },
        fill: {
            type: String,
            default: "none",
        },
        strockeWidth: {
            type: [Number, String],
            default: 2,
        },
    },
    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
