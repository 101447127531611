<template>
    <div class="table-scroll">
        <table :class="customClass">
            <thead>
            <slot name="head"></slot>
            </thead>
            <tbody>
            <slot name="body"></slot>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: "TableBlock",
    props: {
        customClass: {
            typeof: String,
            default: ''
        }
    },
}
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

.table-scroll {
  overflow-x: scroll;

  table {
    border-spacing: 0;
    min-width: 100%;

    thead {
      border-bottom: 1px solid #809CB1;
      font-weight: 700;
      left: 0;

      th {
        padding: 10px;
        white-space: nowrap;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #809CB1;

        span {
          display: block;
        }

        .my-select {
          margin-top: 12px;

          .label {
            border: unset !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 24px !important;
            color: #333333 !important;
            background: #F7F7F9 !important;
            border-radius: 0;
            max-height: 40px;
          }
        }

        .input {
          border: unset !important;
          outline: unset !important;
          margin-top: 12px;

          .v-input__control {
            border: none !important;
            outline: unset !important;

            .v-select__slot {
              max-height: 40px !important;
            }

            .v-input__slot {
              border-radius: 0px !important;
              min-height: 40px !important;
              background: #F7F7F9 !important;
              border: unset !important;
              outline: unset !important;
              border-color: red !important;
              font-style: normal !important;
              font-weight: 400 !important;
              font-size: 14px !important;
              line-height: 24px !important;
              color: #333333 !important;

              input {
                &::placeholder {
                  font-style: normal !important;
                  font-weight: 400 !important;
                  font-size: 14px !important;
                  line-height: 24px !important;
                  color: #333333 !important;
                }
              }

              fieldset {
                border: none !important;
              }
            }

            .v-text-field__details {
              display: none;
            }

          }
        }
      }
    }

    tbody {
      tr {

        td {
            border-top: 1px solid #809CB1 ;
          padding: 10px;
          background: #fff;
          transition: all 0.3s;
        }

        &:not(:last-child) {
          td {
            //border-bottom: 1px solid #809CB1;
          }
        }

        &:hover {
          td {
            // background: #f5f6f7;
          }
        }
      }
    }
  }
}
</style>