<template>
    <section class="grid-section" :style="gridStyle">
        <slot />
    </section>
</template>
<script>
export default {
    name: "grid",
    props: {
        column: {
            typeof: Number,
            default: 1
        },
        gap: {
            typeof: Number,
            default: 1
        },
        customStyle: {
            typeof: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        gridStyle() {
            return {
                gridTemplateColumns: `repeat(${this.column}, 1fr)`,
                gap: this.gap + 'px',
                ...this.customStyle,
            }
        }
    }
}
</script>
<style scoped lang="scss">
.grid-section {
    display: grid;

}
</style>