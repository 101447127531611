<template>
    <div class="history-page">
        <Grid column="1">
            <Block :style="{padding: '0', overflow: 'hidden' }">
                <TableBlock>
                    <template v-slot:head>
                        <tr :style="{verticalAlign: 'top'}">
                            <th class="w-85">
                                <span>#</span>
                                <v-text-field v-model="filter.number" outlined class="input"
                                              placeholder="Все"></v-text-field>
                            </th>
                            <th>
                                <span>Операция</span>
                                <v-text-field v-model="filter.promocode" outlined class="input"
                                              placeholder="Все"></v-text-field>
                            </th>
                            <th ref="dp2">
                                <span>Дата создания</span>
                                <v-text-field v-model="filter.dateStart" outlined class="input"
                                              placeholder="Все" readonly @click="dpShow=true">
                                    <template #append>
                                        <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="1.5"
                                              :iconName="'calendar'"
                                              stroke="#809CB1"/>
                                    </template>
                                </v-text-field>
                                <transition name="fade">
                                    <div class="datepicker-block" v-show="dpShow2">
                                        <Block :custom-style="{padding:'16px',border:'none',boxShadow: '0px 1px 25px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.1)'}">
                                            <v-date-picker
                                                    v-model="filter.date_created"
                                                    :first-day-of-week="1"
                                                    locale="ru-RU"
                                                    :weekday-format="getCustomDay"
                                            ></v-date-picker>
                                        </Block>
                                    </div>
                                </transition>
                            </th>
                            <th>
                                <span>Сумма</span>
                            </th>
                        </tr>
                    </template>
                    <template v-slot:body>

                    </template>
                </TableBlock>
                <div class="table-control">
                    <div class="count-items">
                        <span>Строк на странице:</span>
                        <SelectBlock v-model="filter.count" :items="countItems"></SelectBlock>
                    </div>
                    <div class="pagination">
                        <ul>
                            <li>
                                <button class="ghost" @click="changePage('prev')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                          :iconName="'chevron-left'" fill="#6F727A"/>
                                </button>
                            </li>
                            <li :class="{actvie:filter.page == ''+showListNow}">1</li>
                            <li :class="{actvie:filter.page == ''+showListNow}">{{ +filter.page + 1 }}</li>
                            <li>...</li>
                            <li :class="{actvie:filter.page == showListNow}">{{ countList }}</li>
                            <li>
                                <button class="ghost" @click="changePage('next')">
                                    <Icon width="20" height="20" viewBox="0 0 20 20" strockeWidth="0"
                                          :iconName="'chevron-right'" fill="#6F727A"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Block>
        </Grid>
    </div>
</template>
<script>
import {mapState} from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import {globalMethods} from '@/mixins/globalMethods'

export default {
    name: 'MainPage',
    components: {},
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {
            dpShow: false,
            dpShow2: false,
            dpShow3: false,
            period: [],
            filter: {
                number: '',
                promocode: '',
                date_created: '',
                date_finish: '',
                status: '',
                dateStart: '',
                dateFinish: '',
                page: 1,
            },
            showListNow: 1,
            countList: 8,
            countItems: [
                {
                    value: '10',
                    label: '10',
                },
                {
                    value: '30',
                    label: '30',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '100',
                    label: '100',
                },
            ],
        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        })
    },
    mounted() {
    },
    watch: {
        dpShow: function (val) {
            const clickOutside = (e) => {
                if (this.handleClickOutside(e, 'dp')) {
                    this.$set(this, 'dpShow', false)
                }
            }
            if (val) {
                document.addEventListener('click', clickOutside);
            } else {
                document.removeEventListener('click', clickOutside);
            }
            return () => {
                document.removeEventListener('click', clickOutside);
            };
        },
        dpShow2: function (val) {
            const clickOutside = (e) => {
                if (this.handleClickOutside(e, 'dp2')) {
                    this.$set(this, 'dpShow2', false)
                }
            }
            if (val) {
                document.addEventListener('click', clickOutside);
            } else {
                document.removeEventListener('click', clickOutside);
            }
            return () => {
                document.removeEventListener('click', clickOutside);
            };
        },
        dpShow3: function (val) {
            const clickOutside = (e) => {
                if (this.handleClickOutside(e, 'dp3')) {
                    this.$set(this, 'dpShow3', false)
                }
            }
            if (val) {
                document.addEventListener('click', clickOutside);
            } else {
                document.removeEventListener('click', clickOutside);
            }
            return () => {
                document.removeEventListener('click', clickOutside);
            };
        },
    },
    methods: {
        getCustomDay(date) {
            const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        changePage(state) {
            changePage
            if (state === 'prev') {
                if (this.filter.page > 1) {
                    this.$set(this.filter, 'page', --this.filter.page)
                    this.$set(this, 'showListNow', --this.filter.page)
                }
            } else if (state === 'next') {
                if (this.filter.page < this.countList) {
                    this.$set(this.filter, 'page', ++this.filter.page)
                    this.$set(this, 'showListNow', ++this.filter.page)
                }
            }
        },
    }
}
</script>
<style lang="scss">
.history-page {
  .information-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 44px;

    &__block-1, &__block-2 {
      .nowrap {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 6px;
        white-space: nowrap;
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #333333;
      }
    }

    &__block-3 {
      position: relative;

      .datepicker-block {
        position: absolute;
        top: 60px;

        .dp {
          padding: 16px;
        }

        .gray-row {
          padding: 16px;
          background: #F7F7F9;
        }
      }

      .v-date-picker-header {
        display: flex !important;
        justify-content: space-between !important;
      }
    }

    &__block-4 {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .v-input__append-inner {
    margin: auto !important;
    position: relative;
    top: 0;
    bottom: 0;
  }
}
</style>
